var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "empty-com" }, [
    _c("img", {
      style: { width: _vm.width + "px", height: _vm.height + "px" },
      attrs: { src: require("@/assets/empty.png") },
    }),
    _c("span", [_vm._v("暂无数据")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }